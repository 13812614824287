const PI2 = Math.PI * 2;

/**
 * 
 * @param {{x:Number, y:Number}} startPoint 
 * @param {{x:Number, y:Number}} endPoint 
 * @returns 
 */
const getAngle = (startPoint, endPoint) => {
    let difX = Math.round(endPoint.x - startPoint.x);
    let difY = Math.round(endPoint.y - startPoint.y);
    return Math.atan2(difY, difX);
}

/**
 * 
 * @param {Number} angle 
 * @returns 
 */
const normalizeAngle = angle => {
    const turns = angle / PI2;
    const pct = turns - Math.floor(turns)
    return pct * PI2;
}

const toFixed = (value, places = 5) => {
    return parseFloat(value.toFixed(places))
}

const normalizePct = (value) =>{
    return Math.max(Math.min(value, 1), 0);
}

const clamp = (number, min, max) => {
    return Math.max(min, Math.min(number, max));
  }

export { getAngle, normalizeAngle, PI2, toFixed, normalizePct, clamp }