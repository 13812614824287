import Lenis from "@studio-freight/lenis";
import { footerAccordion, toggleNav, toggleNavChild, addWidthAndHeight } from "@modules/tricks";
import { $ } from "@utils/dom";
import { gsap } from "gsap";

import { query } from "@util/functions";
import { Linear, Quad, Quart } from "gsap/all";
import { MotherLoader, MotherLoaderEvent } from "../zenit/controller/loader";

const SELECTOR = "[data-site-loader]";

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.name = "loader";
  }

  beforeOnce({ next }) {
    const mainWrapperEl = query(".main-wrapper");
    const siteLoaderEl = query(".site-loader");

    mainWrapperEl.classList.remove("hide");
    siteLoaderEl.classList.remove("hide");

    document.body.classList.add("loader");

    return new Promise(resolve => {
      const complete = () => {
        gsap.to(siteLoaderEl, {
          duration: 1,
          autoAlpha: 0,
          delay: 0.25,
          ease: Quart.easeInOut,
          onComplete: () => {
            siteLoaderEl.remove();
          },
          onStart: () => {
            // instance.init();
            footerAccordion();
            resolve();
            addWidthAndHeight();
          }
        });
        gsap.to(mainWrapperEl, { autoAlpha: 1 });
      };

      complete();
    });
  }

  once() {
    return new Promise(resolve => {
      const lenis = new Lenis({
        duration: 1.2,
        easing: t => Math.min(1, 1.001 - 2 ** (-10 * t)), // https://www.desmos.com/calculator/brs54l4xou
        direction: "vertical", // vertical, horizontal
        gestureDirection: "vertical", // vertical, horizontal, both
        smooth: false,
        mouseMultiplier: 1,
        smoothTouch: false,
        touchMultiplier: 2,
        infinite: false
      });

      function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }

      requestAnimationFrame(raf);

      toggleNav();
      toggleNavChild();

      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener("click", function (e) {
          e.preventDefault();
          lenis.scrollTo(this.getAttribute("href"));
        });
      });

      let oldScrollY = window.scrollY;

      window.onscroll = function () {
        if (oldScrollY < window.scrollY) {
          document.body.setAttribute("data-direction", "down");
        } else {
          document.body.setAttribute("data-direction", "up");
        }
        if (window.scrollY > 100) {
          document.body.classList.add("min-scroll");
        } else {
          document.body.classList.remove("min-scroll");
        }
        oldScrollY = window.scrollY;
      };

      document.body.classList.remove("loader");

      resolve();
    });
  }
}

export default SiteLoader;
