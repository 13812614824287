import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from "swiper";

function swiperTestimonials() {
  new Swiper(".testimonials .swiper", {
    modules: [Navigation],
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".testimonials .next",
      prevEl: ".testimonials .prev"
    }
  });
}

function swiperSocial() {
  new Swiper(".social .swiper", {
    modules: [Navigation],
    // loop: true,
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: 2
      }
    },
    navigation: {
      nextEl: ".social .next",
      prevEl: ".social .prev"
    }
  });
}

function swiperTeam() {
  new Swiper(".team .swiper", {
    // loop: true,
    slidesPerView: 1
  });
}

function swiperBrands() {
  new Swiper(".brands .swiper", {
    // loop: true,
    slidesPerView: "auto"
  });
}

function swiperBrandsHome() {
  new Swiper(".brands .swiper", {
    modules: [Autoplay],
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 32,
    speed: 5000,
    autoplay: {
      delay: 50,
      disableOnInteraction: false
    },
    breakpoints: {
      768: {
        spaceBetween: 88
      }
    }
  });
}

function swiperSlides() {
  new Swiper(".slides .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    navigation: {
      nextEl: ".slides .next",
      prevEl: ".slides .prev"
    }
  });
}

function swiperNews() {
  new Swiper(".news .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    navigation: {
      nextEl: ".news .next",
      prevEl: ".news .prev"
    },
    breakpoints: {
      768: {
        slidesPerView: 2
      }
    }
  });
}

function swiperTiles() {
  new Swiper(".module--tile-carousel .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    autoHeight: false,
    navigation: {
      nextEl: ".module--tile-carousel .next",
      prevEl: ".module--tile-carousel .prev"
    },
    breakpoints: {
      768: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 3
      }
    }
  });
}

function swiperUsage() {
  new Swiper(".usage .swiper", {
    modules: [Navigation],
    navigation: {
      nextEl: ".usage .next",
      prevEl: ".usage .prev"
    }
  });
}

function swiperBenefits() {
  new Swiper(".benefits .swiper", {
    modules: [Pagination, Navigation],
    spaceBetween: 56,
    pagination: {
      el: ".benefits .numbers",
      type: "fraction"
    },
    navigation: {
      nextEl: ".benefits .next",
      prevEl: ".benefits .prev"
    }
  });
}

function swiperMediaCarousel() {
  const Swipers = document.querySelectorAll(".module--text-content-media-carousel");
  let i = 0;
  for (i = 0; i < Swipers.length; i++) {
    Swipers[i].classList.add("swiper-container-" + i);
    let swiper = document.querySelector(".swiper-container-" + i);
    let direction = swiper.getAttribute("data-direction");

    if (direction == "horizontal") {
      var slider = new Swiper(".swiper-container-" + i + " .swiper", {
        modules: [Pagination, Navigation],
        spaceBetween: 56,
        pagination: {
          el: ".swiper-container-" + i + " .numbers",
          type: "fraction"
        },
        navigation: {
          nextEl: ".swiper-container-" + i + " .next",
          prevEl: ".swiper-container-" + i + " .prev"
        }
      });
    } else if (direction == "vertical") {
      const images = document.querySelectorAll(".slide__image");
      const textSlides = document.querySelectorAll(".text__slide");
      const prevButton = swiper.querySelector(".prev");
      const nextButton = swiper.querySelector(".next");
      let currentSlide = 0;
      const totalSlides = images.length;

      // Update button states
      function updateControls() {
        if (currentSlide === 0) {
          prevButton.disabled = true; // Disable 'Up' button
          prevButton.classList.remove("active");
        } else {
          prevButton.disabled = false; // Enable 'Up' button
          prevButton.classList.add("active");
        }

        if (currentSlide === totalSlides - 1) {
          nextButton.disabled = true; // Disable 'Down' button
          nextButton.classList.remove("active");
        } else {
          nextButton.disabled = false; // Enable 'Down' button
          nextButton.classList.add("active");
        }
      }

      function updateSlides(newIndex, direction) {
        if (newIndex === currentSlide) return;

        const currentImage = images[currentSlide];
        const currentText = textSlides[currentSlide];

        const newImage = images[newIndex];
        const newText = textSlides[newIndex];

        // Add leaving class to current image based on direction
        if (direction === "next") {
          currentImage.classList.add("leaving-next");
          currentImage.classList.remove("active");
        } else if (direction === "prev") {
          currentImage.classList.add("leaving-prev");
          currentImage.classList.remove("active");
        }

        // Add entering class to new image based on direction
        if (direction === "next") {
          newImage.classList.add("entering-next");
        } else if (direction === "prev") {
          newImage.classList.add("entering-prev");
        }

        // Force reflow to ensure the initial state is applied before adding 'active'
        void newImage.offsetWidth;

        // Add 'active' class to new image to trigger transition
        newImage.classList.add("active");

        // Update text slides
        currentText.classList.remove("active");
        newText.classList.add("active");

        // Listen for transition end to clean up leaving and entering classes
        currentImage.addEventListener("transitionend", function handler(e) {
          if (e.propertyName === "transform") {
            // Ensure it's the transform transition
            currentImage.classList.remove("active", "leaving-next", "leaving-prev");
            currentImage.removeEventListener("transitionend", handler);
          }
        });

        newImage.addEventListener("transitionend", function handler2(e) {
          if (e.propertyName === "transform") {
            newImage.classList.remove("entering-next", "entering-prev");
            newImage.removeEventListener("transitionend", handler2);
          }
        });

        // Update currentSlide index
        currentSlide = newIndex;

        // Update control buttons
        updateControls();
      }

      // Event listener for 'Up' button (previous slide)
      prevButton.addEventListener("click", () => {
        if (currentSlide > 0) {
          let newSlide = currentSlide - 1;
          updateSlides(newSlide, "prev");
        }
      });

      // Event listener for 'Down' button (next slide)
      nextButton.addEventListener("click", () => {
        if (currentSlide < totalSlides - 1) {
          let newSlide = currentSlide + 1;
          updateSlides(newSlide, "next");
        }
      });

      // Initialize by updating controls on load
      updateControls();
    }
  }
}

function swiperBlog() {
  new Swiper("header .swiper", {
    modules: [Navigation],
    spaceBetween: 56,
    navigation: {
      nextEl: "header .next",
      prevEl: "header .prev"
    }
  });
}

function swiperContentCarousel() {
  new Swiper(".module--content-carousel .swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    navigation: {
      nextEl: ".module--content-carousel .next",
      prevEl: ".module--content-carousel .prev"
    }
  });
}

function swiperHome() {
  // Get intial slides
  let slides = document.querySelectorAll(".pageheader__marquee .swiper-slide");

  const swiper = new Swiper(".pageheader__marquee .swiper", {
    modules: [Autoplay],
    centeredSlides: true,
    centeredSlidesBounds: true,
    loop: true,
    freeMode: false,
    spaceBetween: 15,
    autoHeight: false,
    updateOnWindowResize: true,
    autoplay: {
      delay: 9000,
      disableOnInteraction: false
    },
    breakpoints: {
      0: {
        slidesPerView: 1.5
      },
      768: {
        allowTouchMove: false,
        // noSwiping: true,
        // noSwipingClass: "swiper-slide",
        slidesPerView: 1.5
      }
    },
    on: {
      slideChangeTransitionStart: function (swiper) {
        let $wrapperEl = swiper.$wrapperEl;
        let params = swiper.params;
        $wrapperEl.children("." + params.slideClass + "." + params.slideDuplicateClass).each(function () {
          let idx = this.getAttribute("data-swiper-slide-index");
          this.innerHTML = $wrapperEl
            .children(
              "." +
                params.slideClass +
                '[data-swiper-slide-index="' +
                idx +
                '"]:not(.' +
                params.slideDuplicateClass +
                ")"
            )
            .html();
        });
      },

      slideChangeTransitionEnd: function (swiper) {
        swiper.slideToLoop(swiper.realIndex, 0, false);
      },

      click(event) {
        swiper.slideTo(this.clickedIndex);
        swiper.update();
      },

      init: function () {
        const getCurrentSlideVideo = slides[0].querySelector("iframe");
        const getCurrentSlideLottie = slides[0].querySelector("lottie-player");
        const currentPlayer = new Vimeo.Player(getCurrentSlideVideo, { autoplay: true });
        carousel__height__init(slides[0].offsetHeight);
        currentPlayer.play();
        if (getCurrentSlideLottie) {
          setTimeout(() => {
            getCurrentSlideLottie.play();
          }, 1000);
        }
        currentPlayer.on("ended", function () {
          let isPlaying =
            currentPlayer.currentTime > 0 &&
            !currentPlayer.paused &&
            !currentPlayer.ended &&
            currentPlayer.readyState > currentPlayer.HAVE_CURRENT_DATA;
          if (!isPlaying) {
            currentPlayer.pause();
          }
          swiper.slideNext();
        });
      }
    }
  });

  function carousel__height__init(height) {
    const marquee = document.querySelector(".pageheader__marquee .swiper-wrapper");
    marquee.style.height = height + "px";
  }

  // Get all slides once initiated.
  slides = document.querySelectorAll(".pageheader__marquee .swiper-slide");

  window.addEventListener(
    "resize",
    function (event) {
      slides = document.querySelectorAll(".pageheader__marquee .swiper-slide-active");

      carousel__height__init(slides[0].offsetHeight);
    },
    true
  );

  swiper.on("slideChange", function (e) {
    // Stop previous
    slides.forEach(function (slide) {
      let slideIframes = slide.querySelector("iframe");
      let slideLottie = slide.querySelector("lottie-player");

      let previousPlayer = new Vimeo.Player(slideIframes, { autoplay: true });

      if (slideLottie) {
        setTimeout(() => {
          slideLottie.stop();
        }, 1000);
      }

      previousPlayer.setCurrentTime(0);
      let isPlaying =
        previousPlayer.currentTime > 0 &&
        !previousPlayer.paused &&
        !previousPlayer.ended &&
        previousPlayer.readyState > previousPlayer.HAVE_CURRENT_DATA;
      if (!isPlaying) {
        previousPlayer.pause();
      }
    });

    if (slides[swiper.activeIndex]) {
      let slideChangeGetCurrentSlideVideo = slides[swiper.activeIndex].querySelector("iframe");
      let slideChangeGetCurrentSlideLottie = slides[swiper.activeIndex].querySelector("lottie-player");
      let player = new Vimeo.Player(slideChangeGetCurrentSlideVideo, { autoplay: true });

      player.play();

      if (slideChangeGetCurrentSlideLottie) {
        setTimeout(() => {
          slideChangeGetCurrentSlideLottie.play();
        }, 1000);
      }

      player.on("ended", function () {
        swiper.slideNext();
        player.setCurrentTime(0);
        let isPlaying =
          player.currentTime > 0 && !player.paused && !player.ended && player.readyState > player.HAVE_CURRENT_DATA;
        if (!isPlaying) {
          player.pause();
        }
      });
    }
  });
}

function swiperTestimonialsCarousel() {
  new Swiper(".testimonials-carousel .swiper", {
    modules: [Navigation, EffectFade, Autoplay, Pagination],
    loop: true,
    slidesPerView: 1,
    effect: "fade",
    autoHeight: false,
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 8000
    },
    pagination: {
      el: ".pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".testimonials-carousel .next",
      prevEl: ".testimonials-carousel .prev"
    }
  });
}

export {
  swiperTestimonials,
  swiperNews,
  swiperTiles,
  swiperUsage,
  swiperBenefits,
  swiperBlog,
  swiperSocial,
  swiperTeam,
  swiperBrands,
  swiperSlides,
  swiperBrandsHome,
  swiperMediaCarousel,
  swiperContentCarousel,
  swiperHome,
  swiperTestimonialsCarousel
};
